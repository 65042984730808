import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { isString, isEmpty, isNumber } from 'lodash'

import Icon from '../Icon'
import Label from './Label'

const renderAddon = (node = null, addonOptions = {}, options = {}, addonClass = '', iconClass = '') => {
    if (!node) {
        return null
    }

    options.className = cx('input__icon', iconClass)
    return (
        <span className={cx("input__addon", addonClass)} {...addonOptions}>
            {isString(node) ? <Icon icon={node} {...options} /> : node}
        </span>
    )
}

class Input extends PureComponent {
    constructor(props, context) {
        super(props, context)

        this.state = {
            value: (!isEmpty(props.value) || isNumber(props.value)) ? props.value : props.defaultValue
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleBlur = this.handleBlur.bind(this)
        this.getValue = this.getValue.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.state.value) {
            this.setState({
                value: nextProps.value
            })
        }
    }

    handleChange (ev) {
        const value = ev.target.value

        this.setState({
            value
        })

        if (this.props.onChange) {
            this.props.onChange(ev.target.value, this.props.name)
        }
    }

    handleBlur (ev) {
        if (this.props.onBlur) {
            this.props.onBlur(this.state.value)
        }
    }

    focus = () => {
        this.refs.field.focus()
    }

    getValue = () => {
        return this.state.value
    }

    _renderInput = (options = {}) => {
        const { inputProps, theme, ignoreZero } = this.props
        const { value } = this.state
        const inputElementProps = {
            ...inputProps,
            autoComplete: 'off',
            type: this.props.type,
            role: 'input',
            ref: 'field',
            onChange: this.handleChange,
            onBlur: this.handleBlur,
            value: (isNumber(value) && ignoreZero && value === 0) || value === null ? '' : value,
            className: cx('input', theme.field),
        }

        return React.createElement('input', inputElementProps)
    }

    _renderField = () => {
        const { before, after, beforeProps, afterProps, beforeAddonProps, afterAddonProps, theme } = this.props
        if (after || before) {
            return (
                <div className="input__group">
                    {renderAddon(before, beforeAddonProps, beforeProps, theme.beforeAddon, theme.beforeIcon)}
                    {this._renderInput()}
                    {renderAddon(after, afterAddonProps, afterProps, theme.afterAddon, theme.afterIcon)}
                </div>
            )
        } else {
            return this._renderInput()
        }
    }

    render = () => {
        const { label, labelProps, theme } = this.props
        return (
            <div className={cx('field', theme.input)}>
                {label ? <Label children={label} {...labelProps} className={cx('label', theme.label)} /> : null}
                {this._renderField()}
            </div>
        )
    }
}

Input.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    labelProps: PropTypes.object,
    name: PropTypes.string,
    type: PropTypes.string,
    ignoreZero: PropTypes.bool,
    before: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.node
    ]),
    beforeAddonProps: PropTypes.object,
    beforeProps: PropTypes.object,
    after: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.node
    ]),
    afterAddonProps: PropTypes.object,
    afterProps: PropTypes.object,
    inputProps: PropTypes.object,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    theme: PropTypes.shape({
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
        input: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
        field: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
        beforeAddon: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
        beforeIcon: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
        afterAddon: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
        afterIcon: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ])
    })
}

Input.defaultProps = {
    label: null,
    labelProps: {},
    name: '',
    type: 'text',
    ignoreZero: false,
    before: false,
    beforeAddonProps: {},
    beforeProps: {},
    after: false,
    afterAddonProps: {},
    afterProps: {},
    inputProps: {},
    onChange: null,
    onBlur: null,
    value: null,
    defaultValue: '',
    theme: {}
}

export default Input
