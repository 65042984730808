import React, { PureComponent } from 'react';
import Offline from 'offline-js'
import swal from 'sweetalert2'
import './scss/App.scss'

import { createDB, getCurrentEvent, removeCurrentEvent, setCurrentEvent, saveRecord, removeRecord } from './lib/storage'
import { fetchRequest } from './middleware/api'

import FrontEndContainer from './container/FrontEndContainer'
import AdminContainer from './container/AdminContainer'
import Header from './components/Header'

const BROWSER_DB = 'events_db'
const BROWSER_TABLE = 'records'

class App extends PureComponent {
    constructor(props, context) {
        super(props, context)

        this.state = {
            entity: getCurrentEvent(),
            showAdmin: false,
            isOnline: window.Offline.check().offline
        }

        this._db = createDB(BROWSER_DB, BROWSER_TABLE)

        this._initOfflineEvents()

        this.handleShowAdmin = this.handleShowAdmin.bind(this)
        this._handleSetEvent = this._handleSetEvent.bind(this)
        this._handleFinishEvent = this._handleFinishEvent.bind(this)
        this._saveRecord = this._saveRecord.bind(this)
    }

    _initOfflineEvents = () => {
        window.Offline.on('confirmed-down', () => {
            this.setState({
                isOnline: false
            })
        })

        window.Offline.on('confirmed-up', () => {
            this._sendNotUploadRecords()
            this.setState({
                isOnline: true
            })
        })
    }

    handleShowAdmin = () => {
        if (process.env.NODE_ENV === 'development') {
            this.setState({
                showAdmin: !this.state.showAdmin
            })
        } else {
            if (!this.state.showAdmin) {
                swal({
                    title: "Acceso privado",
                    type: 'warning',
                    input: 'password',
                    confirmButtonText: 'Entrar',
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    preConfirm: value => {
                        return new Promise((resolve, reject) => {
                            if (value !== 'diver2018') {
                                reject('Contraseña incorrecta')
                            } else {
                                resolve()
                            }
                        })
                    }
                }).then(result => {
                    if (!result.hasOwnProperty('dismiss')) {
                        this.setState({
                            showAdmin: true
                        })
                    }
                }).catch(swal.noop)
            } else {
                this.setState({
                    showAdmin: false
                })
            }
        }
    }

    // Admin
    _handleSetEvent = entity => {
        setCurrentEvent(entity)

        this.setState({
            entity
        })
    }

    _handleFinishEvent = event => {
        if (!this.state.isOnline) {
            return Promise.reject('offline_mode')
        }

        return fetchRequest(`/events/${event.id}/finish`, {
            method: 'POST'
        }).then(() => {
            const currentRace = getCurrentEvent()
            if (currentRace.id === event.id) {
                removeCurrentEvent()

                this.setState({
                    entity: {}
                })
            }
        })
    }

    // Forms
    _sentRecordToServer = data => {
        return fetchRequest(`/events/${data.event_id}/record`, {
            method: 'PUT',
            body: data
        })
    }

    _sendNotUploadRecords = () => {
        // Subir eventos creados pero no guardados en BBDD
        const table = this._db.table(BROWSER_TABLE)

        // Subir registros de los eventos no guardados
        return table
            .toArray(records => {
                records.map(race =>
                    this._sentRecordToServer(race)
                        .then(response => {
                            removeRecord(table, race._id)
                                .catch(r => console.error(r))
                        })
                        .catch(response => console.error('error on save'))
                )
            })
    }

    _saveRecord = record => {
        const { isOnline, entity } = this.state

        const data = Object.assign({}, record, {
            event_id: entity.id
        })

        return isOnline
            ? this._sentRecordToServer(data)
            : saveRecord(this._db.table(BROWSER_TABLE), data)
    }

    render = () => {
        const className = ['app', 'u-h100'];
        if (this.state.entity.brand) {
            className.push('app--' + this.state.entity.brand)
        }

        return (
          <div className={className.join(" ")}>
            <Header
                entity={this.state.entity}
                isOnline={this.state.isOnline}
                isAdmin={this.state.showAdmin}
                handleAdmin={this.handleShowAdmin}
            />
            {this.state.showAdmin
                ? <AdminContainer
                    entity={this.state.entity}
                    handleChange={this._handleSetEvent}
                    handleFinish={this._handleFinishEvent}
                    isOnline={this.state.isOnline} />
                : <FrontEndContainer
                    entity={this.state.entity}
                    saveRecord={this._saveRecord} />
            }
          </div>
        );
    }
}

export default App;
