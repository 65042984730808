import React from 'react'

const VideoPage = ({ entity }) => {
    return (
        <section className="has-text-centered">
            <div className="video-wrapper">
                <video className="video" autoPlay loop muted>
                    <source src={`${process.env.PUBLIC_URL}/static/video/video-${entity.brand}.mp4`} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            {entity.text ? <p className="m--15" children={entity.text} /> : null}
        </section>
    )
}

export default VideoPage
