import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { fetchRequest } from '../middleware/api'
import { removeCurrentEventIfMatch } from '../lib/storage'

import EventItem from '../components/Admin/Event'
import EventForm from '../components/Admin/EventForm'
import Modal from '../components/Modal'
import Icon from '../components/Icon'

class AdminContainer extends PureComponent {
    constructor(props, context) {
        super(props, context)

        this.state = {
            error: false,
            isLoading: true,
            modalProps: {
                isOpen: false
            },
            events: []
        }

        this._saveEvent = this._saveEvent.bind(this)

        this.fetchAllEvents()
    }

    fetchAllEvents = () => {
        if (!this.props.isOnline) {
            this.setState({
                error: 'Sin internet',
                isLoading: false
            })
        } else {
            fetchRequest('/events')
            .then(({json, response}) => {
                this.setState({
                    events: json.data,
                    isLoading: false
                })
            }).catch(response => {
                this.setState({
                    error: 'Error al cargar las pruebas',
                    isLoading: false
                })
            })
        }
    }

    _saveEvent = data => {
        fetchRequest('/events', {
            method: 'PUT',
            body: data
        })
        .then(({json, response}) => {
            this.setState({
                events: [
                    ...this.state.events,
                    json.data
                ],
                modalProps: {
                    isOpen: false
                }
            })
        })
    }

    _finishEvent = event => {
        this.props.handleFinish(event)
            .then(response => {
                removeCurrentEventIfMatch(event.id)

                this.setState({
                    events: this.state.events.filter(it => it.id !== event.id)
                })
            })
    }

    _fullRow = text => {
        return (
            <div className="table__row">
                <div className="table__col has-text-centered">
                    {text}
                </div>
            </div>
        )
    }

    _offlineMessage = () => {
        const { isOnline } = this.props

        if (isOnline) {
            return null
        }

        return (
            <article className="message is-danger">
                <div className="message-header flex--start">
                    <Icon icon="warning" className="icon--20 m-r--10" />
                    <p>Sin conexion a internet</p>
                </div>
                <div className="message-body is-size-7">
                    <p>Para poder añadir eventos o obtener los disponibles, necesitas disponer de una de conexión a internet. Una vez connectado a internet, se sincronizarán todos los registros pendientes.</p>
                </div>
            </article>
        )
    }

    _renderAvailableEvents = () => {
        const { isLoading, events } = this.state
        const { isOnline, entity } = this.props

        if (isLoading || !isOnline) {
            return this._fullRow(isLoading ? 'Cargando datos...' : <strong className="has-text-danger u-centered" children="Sin conexión a internet" />)
        }

        if (!events.length) {
            return this._fullRow('No hay eventos')
        }

        return events.map(it => <EventItem item={it} current={entity} key={it.id} onClickUse={() => this.props.handleChange(it)} handleFinish={() => this._finishEvent(it)} />)
    }

    _addEvent = () => {
        this.setState({
            modalProps: {
                isOpen: true,
                component: EventForm,
                componentProps: {
                    handleSubmit: this._saveEvent
                }
            }
        })
    }

    _closeModal = () => {
        this.setState({
            modalProps: {
                isOpen: false
            }
        })
    }

    _renderModal = () => {
        const { modalProps } = this.state

        if (!modalProps.isOpen) {
            return null
        }

        return React.createElement(
            Modal,
            {
                ...modalProps,
                handleClose: this._closeModal
            }
        )
    }

    render = () => {
        const { isOnline } = this.props

        return (
            <section className="app__main--inner">
                {this._offlineMessage()}
                <article>
                    <div className="is-flex flex--middle flex--between m-b--10">
                        <h1 className="m--0 is-size-4">Eventos disponibles</h1>
                        <span className="button is-success" onClick={isOnline ? this._addEvent : null} disabled={!isOnline}>Añadir un evento</span>
                    </div>
                    <div className="table is-striped">
                        <header className="table__header"></header>
                        <section className="table__body">
                            {this._renderAvailableEvents()}
                        </section>
                    </div>
                </article>
                {this._renderModal()}
            </section>
        )
    }
}

AdminContainer.proptypes = {
    entity: PropTypes.object,
    handleChange: PropTypes.func.isRequired,
    handleFinish: PropTypes.func.isRequired,
    isOnline: PropTypes.bool.isRequired
}

AdminContainer.defaultsProps = {
    entity: {}
}

export default AdminContainer
