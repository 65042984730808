import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Icon = ({ icon, className, svgClassName, other }) => {
    const _className = cx(`icon--${icon}`, 'icon', className)
    const _svgClassname = cx('icon__svg', svgClassName)

    return (
        <span className={_className} {...other}>
            <svg className={_svgClassname} aria-hidden="true">
                <use xlinkHref={`${window.__config.ASSETSICONS}#icon-${icon}`}></use>
            </svg>
        </span>
    )
}

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    className: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    svgClassName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
}

Icon.defaultProps = {
    className: '',
    svgClassName: ''
}

export default Icon
