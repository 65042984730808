import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash.isequal'
import isEmpty from 'lodash.isempty'
import every from 'lodash.every'
import Flatpickr from 'react-flatpickr'
import { Spanish } from "flatpickr/dist/l10n/es.js"
import cx from 'classnames'

import Label from '../Form/Label'
import Input from '../Form/Input'
import Select from '../Form/Select'

const parseDate = date => {
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    return `${day}/${month}/${year}`
}

const BRANDOPTIONS = [
    {label: '100% Half', value: 'half'},
    {label: '100% Tri', value: 'tri'},
    {label: 'Maratón Vías Verdes', value: 'mvv'}
]

const isValidValue = value => typeof value !== "boolean" ? !isEmpty(value) : !!value

const nextEntityIsFinished = entity => {
    return every(entity, field => isValidValue(field))
}

class EventForm extends PureComponent {
    constructor(props, context) {
        super(props, context)

        this.state = {
            isSaving: false,
            hasFinish: false,
            entity: {
                title: '',
                text: '',
                date_start: '',
                date_end: '',
                brand: ''
            }
        }
    }

    _updateEntity = (key, value) => {
        if (isEqual(this.state.entity[key], value)) {
            return
        }

        const nextEntity = {
            ...this.state.entity,
            [key]: value
        }

        this.setState({
            entity: nextEntity,
            hasFinish: nextEntityIsFinished(nextEntity)
        })
    }

    _setProperty = (value, name) => this._updateEntity(name, value)

    _setSelectProperty = (option, options) => this._updateEntity(options.name, option[options.valueKey])

    _handleSubmit = () => {
        if (!this.state.hasFinish) {
            return
        }

        this.setState({
            isSaving: true
        })

        this.props.handleSubmit(this.state.entity)
    }

    render = () => {
        const { handleClose } = this.props
        const { entity, hasFinish, isSaving } = this.state
        const today = (new Date()).setHours(0, 0, 0, 0)

        return (
            <div className="modal-card">
                <header className="modal-card__head">
                    <h2 className="modal-card__title">
                        Añadir nuevo evento
                    </h2>
                    <span className="modal__close close--dark" onClick={handleClose}></span>
                </header>
                <section className="modal-card__body">
                    <Input
                        label="Nombre"
                        name="title"
                        value={entity.title}
                        onChange={this._setProperty}
                    />
                    <Input
                        label="Texto"
                        name="text"
                        value={entity.text}
                        onChange={this._setProperty}
                    />
                    <div className="row field">
                        <div className="col-xs-12 col-sm-6">
                            <div className="field">
                                <Label children="Fecha inicio" />
                                <Flatpickr
                                    className="input"
                                    options={{
                                        dateFormat: "d/m/Y",
                                        minDate: today,
                                        disableMobile: true,
                                        locale: Spanish
                                    }}
                                    onChange={val => this._setProperty(parseDate(val[0]), 'date_start')}
                                />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="field">
                                <Label children="Fecha final" />
                                <Flatpickr
                                    className="input"
                                    options={{
                                        dateFormat: "d/m/Y",
                                        minDate: today,
                                        disableMobile: true,
                                        locale: Spanish
                                    }}
                                    onChange={val => this._setProperty(parseDate(val[0]), 'date_end')}
                                />
                            </div>
                        </div>
                    </div>
                    <Select
                        label="Marca"
                        name="brand"
                        value={entity.brand}
                        options={BRANDOPTIONS}
                        onChange={this._setProperty}
                        empty="Selecciona uno"
                    />
                </section>
                <footer className="modal-card__foot">
                    <div className="buttons is-right u-w100">
                        <span className="button is-danger" onClick={handleClose}>Cancelar</span>
                        <span className={cx("button is-success", {'is-loading': isSaving})} disabled={!hasFinish} onClick={hasFinish && !isSaving ? this._handleSubmit : null}>Guardar</span>
                    </div>
                </footer>
            </div>
        )
    }
}

EventForm.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
}

export default EventForm
