import React from 'react'
import PropTypes from 'prop-types'

const Message = ({ title, text }) => {
    return (
        <article className="message is-danger">
            <header className="message-header">
                <p>{title}</p>
            </header>
            <div className="message-body">
                {text}
            </div>
        </article>
    )
}

Message.propTypes = {
    title: PropTypes.string,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ])
}
Message.defaultProps = {
    title: '',
    text: ''
}

export default Message
