import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Bubble = ({ type, className }) => {
    return (
        <span
            className={cx('bubble', {
                [`bubble--${type}`]: true
            }, className)}
        ></span>
    )
}

Bubble.propTypes = {
    type: PropTypes.string.isRequired,
    className: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
}

Bubble.defaultProps = {
    className: ''
}

export default Bubble
