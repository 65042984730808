import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { API_ROOT } from '../../middleware/api'

const Event = ({ item, current, onClickUse, handleFinish }) => {
    const isCurrent = current.id === item.id
    return (
        <div className={cx("table__row", { 'table__row--current': isCurrent})}>
            <div className="table__col">
                <span className="txt-bold is-block">{item.title}</span>
                <span className="is-size-7">{item.start} - {item.end} </span>
            </div>
            <div className="table__col has-text-right">
                <span className="is-block">
                    {item.type === 'models' ? 'Modelos' : 'Participar'}
                </span>
                <span className="is-block">
                    {item.records_count}
                </span>
            </div>
            <div className="table__col">
                <div className="buttons is-right">
                    <span
                        className="button is-small is-danger"
                        onClick={handleFinish}
                    >Finalizar</span>
                    <span
                        className="button is-info is-small"
                        disabled={isCurrent}
                        onClick={!isCurrent ? onClickUse : null}
                    >Usar</span>
                    <a
                        href={`https://app2.statsport.es/events/${item.id}/excel`}
                        className="button is-small"
                        target="_blank"
                    >Excel</a>
                </div>
            </div>
        </div>
    )
}

Event.propTypes = {
    item: PropTypes.object.isRequired,
    current: PropTypes.object.isRequired,
    onClickUse: PropTypes.func.isRequired,
    handleFinish: PropTypes.func.isRequired
}

export default Event
