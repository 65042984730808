import React from 'react'

const Politica = () => {
    return (
        <div className="content proteccion-datos">
            <h3>Responsable de los datos</h3>
            <p>
                Nombre: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; STAT SPORT CLUB
                <br />
                CIF&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; G66949413
                <br />
                Dirección:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; C/Ramon Turro 47 08005 Barcelona
                <br />
                Teléfono:&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;682 468 792
                <br />
                Mail:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;club@statsport.es
            </p>
            <p>Al aceptar esta política de privacidad, Ud. acepta expresamente las siguientes finalidades</p>
            <h3>Promoción de nuestras actividades y servicios</h3>
            <p>Al aceptar recibir información sobre nuevos eventos y productos, acepta las siguientes finalidades</p>
            <ul>
                <li>Recibir comunicaciones e información sobre los eventos gestionados por el club.</li>
                <li>Recepcion de comunicaciones promocionales de productos comercializados por la empresa.</li>
            </ul>
            <h3>Destinatarios</h3>
            <p>Los datos de clientes y proveedores solo serán comunicados a organismos oficiales cuando así sea requerido por organismos públicos en cumplimiento de la legislación vigente o a encargados de tratamiento responsables de realizar gestiones encomendadas.</p>
            <p>Los datos se comunicarán a otras empresas &nbsp;vinculadas como Diversport, S.L.&nbsp; para fines administrativos internos, incluido el tratamiento de datos personales de clientes, empleados y asistentes a eventos deportivos</p>
            <h3>Derechos</h3>
            <p>Cualquier persona tiene derecho a obtener confirmación sobre si estamos tratando datos personales que les conciernan, o no. Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.</p>
            <p>En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos el plazo por el que nos veamos obligados en base a la legislación vigente.</p>
            <p>En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos. Nuestra empresa dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones. Usted puede ejercer sus derechos de acceso, rectificación o cancelación.</p>
            <p>Si no desea recibir comunicaciones promocionales de nuestra empresa por alguno o todos los medios de comunicación, puede comunicar su deseo de oponerse a la finalidad de promoción de nuestros productos y servicios, lo que no irá en detrimento de los restantes fines.</p>
            <p>En el ejercicio de los derechos precedentes puede dirigirse por mail a MAIL o a nuestras oficinas C/Ramon Turro 47 - 08005 Barcelona, aportando siempre una copia de su DNI. La respuesta al ejercicio de derechos se realizará al domicilio que figure en los contratos suscritos con nuestra empresa o en el que figure en el documento de ejercicio de los derechos ARCO.</p>
            <p>En caso de que no atendamos al ejercicio de derechos expresados en este apartado, Ud. está facultado para acudir&nbsp; o presentar una reclamación ante la agencia de protección de datos, Ud. Dispone de toda la información a efectos de esta reclamación en la página <a href="http://www.agpd.es">www.agpd.es</a></p>
            <h3>Procedencia de los datos</h3>
            <p>Para proveedores, clientes y contactos, los datos de que disponemos son los que nos fueron proporcionados en el momento de establecer la relación comercial o el contacto, así como los datos derivados de las gestiones y actualizaciones posteriores.</p>
            <p>Los datos de los participantes en las competiciones deportivas serán los proporcionados en el momento de la inscripción</p>
        </div>
    )
}

export default Politica
