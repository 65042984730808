import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'

const FooterPageLinks = ({ page, handleNextPage, handlePrevPage, enableSubmit, handleSubmit, nextText, acceptText }) => {
    if (page === 0) {
        return <span className="button button--app is-block is-large is-uppercase" onClick={handleNextPage} children={nextText} />
    } else if (page === 1) {
        return (
            <div className="buttons">
                <span className="button is-large is-grey" onClick={handlePrevPage}>
                    <Icon icon="left-arrow" className="icon--25" />
                </span>
                <span
                    className="button button--app is-large is-uppercase is-flex-inline is-grow m-l--15"
                    onClick={enableSubmit ? handleSubmit : null}
                    disabled={!enableSubmit}
                    children={acceptText}
                />
            </div>
        )
    } else {
        return null
    }
}

FooterPageLinks.propTypes = {
    page: PropTypes.number.isRequired,
    handleNextPage: PropTypes.func.isRequired,
    handlePrevPage: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    enableSubmit: PropTypes.bool,
    nextText: PropTypes.string,
    acceptText: PropTypes.string
}
FooterPageLinks.defaultProps = {
    enableSubmit: false,
    nextText: "Siguiente",
    acceptText: "Aceptar"
}

export default FooterPageLinks
