import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { isObject, isEqual, map as _map } from 'lodash'
import cx from 'classnames'

import Label from './Label'

const renderOption = ({ option, properties }) => {
    return <option value={option[properties.value]} key={option[properties.value]}>{option[properties.label]}</option>
}

const renderOptions = ({ options, properties }) => {
    const hasOptGroup = !Array.isArray(options)
    return _map(options, (item, key) => {
        if (hasOptGroup) {
            return (
                <optgroup label={key} key={key}>
                    {_map(item, (label, value) => {
                        return renderOption({ option: {label, value}, properties })
                    })}
                </optgroup>
            )
        } else {
            return renderOption({ option: item, properties })
        }
    })
}

const parseValue = (value, valueKey) => {
    return isObject(value) ? value[valueKey] : value
}

class Select extends PureComponent {
    constructor(props, context) {
        super(props, context)

        this.state = {
            value: parseValue(props.value, props.valueKey),
            emptyOption: {
                [props.labelKey]: props.empty,
                [props.valueKey]: ""
            }
        }

        this.handleChange = this.handleChange.bind(this)
    }

    componentWillReceiveProps = nextProps => {
        if (!isEqual(nextProps.value, this.props.value)) {
            this.setState({
                value: parseValue(nextProps.value, nextProps.valueKey),
            })
        }
    }

    handleChange = ev => {
        const { onChange, name } = this.props

        this.setState({
            value: ev.currentTarget.value
        })

        if (onChange) {
            onChange(
                ev.currentTarget.value,
                name
                // {name: name, valueKey: valueKey}
            )
        }
    }

    render = () => {
        const { value, emptyOption } = this.state
        const { empty, label, options, labelKey, valueKey, theme, isLoading } = this.props

        const properties = {
            label: [labelKey],
            value: [valueKey],
            // handleClick: this.handleChange
        }

        const fieldClassName = cx("input", {
            'is-empty': !value
        }, theme.field)

        return (
            <div className={cx("field field--select", theme.input)}>
                {label ? <Label children={label} className={theme.label} /> : null}
                <div className={cx("select-wrapper", {'is-loading': isLoading})}>
                    <select className={fieldClassName} value={value} disabled={isLoading} onChange={this.handleChange}>
                        {empty === false
                            ? null
                            : renderOption({
                                option: emptyOption,
                                properties
                            })
                        }
                        {!isLoading ? renderOptions({ options, properties }) : null}
                    </select>
                </div>
            </div>
        )
    }
}

Select.propTypes = {
    options: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    onChange: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    empty: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    isLoading: PropTypes.bool,
    name: PropTypes.string,
    labelKey: PropTypes.string,
    valueKey: PropTypes.string,
    theme: PropTypes.shape({
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
        input: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
        field: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ])
    })
}

Select.defaultProps = {
    options: [],
    onChange: null,
    label: null,
    value: '',
    empty: 'Select One',
    isLoading: false,
    name: '',
    labelKey: 'label',
    valueKey: 'value',
    theme: {}
}

export default Select
