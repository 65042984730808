import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const SpinLoading = ({ animate, finish, onlyOnFinish, type, className }) => {
    const classnames = cx('sloading', {
        'is-animate': animate,
        'is-finish': finish,
        [`is-${type}`]: (onlyOnFinish ? finish : true) && type
    }, className)

    return (
        <div className={classnames}>
            <span className="sloading__figure sfigure--tick">
                <span className="sfigure__line sfigure__line--left"></span>
                <span className="sfigure__line sfigure__line--right"></span>
            </span>
            <span className="sloading__figure sfigure--error">
                <span className="sfigure__line sfigure__line--left"></span>
                <span className="sfigure__line sfigure__line--right"></span>
            </span>
        </div>
    )
}

SpinLoading.propTypes = {
    animate: PropTypes.bool,
    finish: PropTypes.bool,
    onlyOnFinish: PropTypes.bool,
    type: PropTypes.string,
    className: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
}

SpinLoading.defaultProps = {
    animate: false,
    finish: false,
    onlyOnFinish: false,
    type: '',
    className: '',
}

export default SpinLoading
