import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash.isequal'

import { nextStateIsFinished, isRequired } from '../../lib/functions'

import Politica from './ProteccionDatos'
import Input from "../Form/Input"
import SwitchWrapper from '../Form/SwitchWrapper'

class LeadsPage extends PureComponent {
    constructor(props, context) {
        super(props, context)

        this.state = {
            entity: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                state: '',
                type: 'leed',
                newsletter: false,
                tos: false
            },
            required: {
                first_name: true,
                last_name: false,
                state: true,
                email: true,
                phone: false,
                newsletter: false,
                tos: true
            }
        }
    }

    getData = () => this.state.entity

    _checkEnableSubmit = (state = this.state) => {
        this.props.enableSubmit(nextStateIsFinished(state))
    }

    _updateEntity = (key, value) => {
        if (isEqual(this.state.entity[key], value)) {
            return
        }

        let nextState = {
            ...this.state,
            entity: {
                ...this.state.entity,
                [key]: value
            }
        }

        this._checkEnableSubmit(nextState)

        this.setState(nextState)
    }

    _setProperty = (value, name) => this._updateEntity(name, value)

    _isRequired = value => isRequired(this.state, value)

    render = () => {
        const { entity, required } = this.state

        return (
            <section className="app__main--inner">
                <div className="columns is-multiline">
                    <div className="column is-half">
                        <Input
                            label="NOMBRE"
                            labelProps={{
                                addon: this._isRequired(required['first_name']) ? '*' : null,
                                addonClassName: 'has-text-danger'
                            }}
                            onChange={this._setProperty}
                            name="first_name"
                            value={entity.first_name}
                        />
                    </div>
                    <div className="column is-half">
                        <Input
                            label="APELLIDOS"
                            labelProps={{
                                addon: this._isRequired(required['last_name']) ? '*' : null,
                                addonClassName: 'has-text-danger'
                            }}
                            onChange={this._setProperty}
                            name="last_name"
                            value={entity.last_name}
                        />
                    </div>
                    <div className="column is-half">
                        <Input
                            label="E-MAIL DE CONTACTO"
                            type="email"
                            labelProps={{
                                addon: this._isRequired(required['email']) ? '*' : null,
                                addonClassName: 'has-text-danger'
                            }}
                            inputProps={{
                                type: "email",
                                autoCapitalize: "none"
                            }}
                            onChange={this._setProperty}
                            name="email"
                            value={entity.email}
                        />
                    </div>
                    <div className="column is-half">
                        <Input
                            label="TELÉFONO"
                            type="tel"
                            labelProps={{
                                addon: this._isRequired(required['phone']) ? '*' : null,
                                addonClassName: 'has-text-danger'
                            }}
                            inputProps={{
                                type: "tel"
                            }}
                            onChange={this._setProperty}
                            name="phone"
                            value={entity.phone}
                        />
                    </div>
                    <div className="column is-half">
                        <Input
                            label="PROVÍNCIA"
                            labelProps={{
                                addon: this._isRequired(required['state']) ? '*' : null,
                                addonClassName: 'has-text-danger'
                            }}
                            onChange={this._setProperty}
                            name="state"
                            value={entity.state}
                        />
                    </div>
                </div>
                <Politica />
                <div className="tos">
                    <SwitchWrapper
                        onChange={this._setProperty}
                        checked={entity.newsletter}
                        name="newsletter"
                    />
                    <label className="tos__text" htmlFor="check-newsletter">Deseo recibir comunicaciones promocionales de Stat Sport Club.</label>
                </div>
                <div className="tos">
                    <SwitchWrapper
                        onChange={this._setProperty}
                        checked={entity.tos}
                        name="tos"
                    />
                    <label className="tos__text" htmlFor="check-tos">He leído y acepto la política de privacidad.</label>
                </div>
            </section>
        )
    }
}

LeadsPage.proptypes = {
    enableSubmit: PropTypes.func.isRequired
}

export default LeadsPage
