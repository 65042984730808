import React from 'react'
import PropTypes from 'prop-types'

import Icon from './Icon'
import Bubble from './Bubble'

const Online = () => {
    return (
        <div className="online is-flex flex--middle">
            <Bubble type='success' className="m-l--0" />
            <span className="online__text has-text-weight-bold">Online</span>
        </div>
    )
}

const Offline = () => {
    return (
        <div className="online is-flex flex--middle">
            <Bubble type='error' className="m-l--0" />
            <span className="online__text has-text-weight-bold">Offline</span>
        </div>
    )
}

const Logo = ({ brand }) => {
    if (!brand) {
        return null;
    }

    return <img src={`${process.env.PUBLIC_URL}/static/img/logo${brand}.png`} className="app__header-logo" height="65" alt={brand} />
}

const Header = ({ entity, isAdmin, handleAdmin, isOnline }) => {
    return (
        <header className="app__header">
            <div className="is-flex flex--between flex--middle">
                <div className="is-flex flex--middle">
                    {!isAdmin ? <Logo brand={entity.brand} /> : null}
                    {isAdmin ? <h2 className="m--0">Admin</h2> : null}
                </div>
                <div className="is-flex flex--middle">
                    {isOnline ? <Online /> : <Offline />}
                    <div className="m-l--15 lh1" onClick={handleAdmin}>
                        <Icon icon="settings" className="icon--32" />
                    </div>
                </div>
            </div>
        </header>
    )
}

Header.propTypes = {
    entity: PropTypes.object,
    handleAdmin: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool,
    isOnline: PropTypes.bool
}
Header.defaultProps = {
    entity: {},
    isAdmin: false,
    isOnline: false
}

export default Header
