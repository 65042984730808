import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Switch from 'react-ios-switch'

class SwitchWrapper extends PureComponent {
    onChange = value => {
        if (!this.props.onChange) {
            return;
        }

        this.props.onChange(value, this.props.name)
    }

    render = () => React.createElement(Switch, {
        ...this.props,
        onChange: this.onChange
    })
}

SwitchWrapper.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string
}
SwitchWrapper.defaultProps = {
    onChange: null,
    name: null
}

export default SwitchWrapper
