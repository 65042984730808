import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { isString } from 'lodash'

const renderAddon = (addon, className) => {
    if (!addon) {
        return null
    } else if (isString(addon)) {
        return <span className={cx('label__addon', className)} children={addon} />
    }

    return addon
}

const Label = ({ children, addon, addonClassName, className }) => {
    return (
        <label className={cx('label', className)}>
            {children}
            {renderAddon(addon, addonClassName)}
        </label>
    )
}

Label.propTypes = {
    // title: PropTypes.string.isRequired,
    addon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    addonClassName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    className: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
}

Label.defaultProps = {
    addon: null,
    addonClassName: '',
    className: ''
}

export default Label
