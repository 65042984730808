import isEmpty from 'lodash.isempty'
import every from 'lodash.every'

const isValidValue = value => typeof value !== "boolean" ? !isEmpty(value) : !!value

export const isRequired = (state, rules) => {
    if (typeof rules === 'boolean') {
        return rules
    }

    return Array.isArray(rules) && every(rules, rule => rule.eq ? state.entity[rule.field] === rule.value : state.entity[rule.field] !== rule.value)
}

export const nextStateIsFinished = state => {
    return every(state.required, (rules, field) => {

        if (!isRequired(state, rules)) {
            return true
        }

        return isValidValue(state.entity[field])
    })
}
