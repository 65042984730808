import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import cx from 'classnames'

ReactModal.setAppElement('body')
ReactModal.defaultStyles = {}

class Modal extends PureComponent {
    constructor(props, context) {
        super(props, context)

        this.handleKeyboardInput = this.handleKeyboardInput.bind(this)
    }

    componentDidMount = () => {
        if (this.props.isOpen && this.props.enableKeyboardInput) {
			window.addEventListener('keydown', this.handleKeyboardInput)
		}
    }

	componentWillReceiveProps = nextProps => {
		// add/remove event listeners
		if (!this.props.isOpen && nextProps.isOpen && nextProps.enableKeyboardInput) {
			window.addEventListener('keydown', this.handleKeyboardInput)
		}
		if (!nextProps.isOpen && nextProps.enableKeyboardInput) {
			window.removeEventListener('keydown', this.handleKeyboardInput)
		}
	}

    componentWillUnmount = () => {
		if (this.props.enableKeyboardInput) {
			window.removeEventListener('keydown', this.handleKeyboardInput)
		}
	}

    handleKeyboardInput = ev => {
		if (ev.keyCode === 27) { // esc
			this.props.handleClose()
			return true
		}

        return false
	}

    _renderContent = () => {
        const { component, componentProps, handleClose } = this.props

        if (!component) {
            return null;
        }

        return React.createElement(
            component,
            {
                ...componentProps,
                handleClose: handleClose
            }
        )
    }

    render = () => {
        const { isOpen, modalProps } = this.props

        return (
            <ReactModal
                isOpen={isOpen}
                portalClassName="modal"
                overlayClassName={cx("modal__background", modalProps.overlay)}
                className="modal__react"
                contentLabel="Modal"
                ref="modal"
                ariaHideApp
                parentSelector={() => document.body}
            >
                {this._renderContent()}
            </ReactModal>
        )
    }
}

Modal.propTypes = {
    isOpen: PropTypes.bool,
    //component: PropTypes.node,
    enableKeyboardInput: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    componentProps: PropTypes.object,
    modalProps: PropTypes.object
}
Modal.defaultProps = {
    isOpen: false,
    component: null,
    enableKeyboardInput: true,
    componentProps: {},
    modalProps: {}
}

export default Modal
