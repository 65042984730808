import Dexie from 'dexie'

const LOCALSTORAGE_KEY = 'event'

export const setCurrentEvent = event => {
    window.localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(event));
}

export const getCurrentEvent = () => {
    const event = window.localStorage.getItem(LOCALSTORAGE_KEY)
    if (!event) {
        return {};
    }

    return JSON.parse(event)
}

export const removeCurrentEvent = () => {
    window.localStorage.removeItem(LOCALSTORAGE_KEY);

    return true
}

export const removeCurrentEventIfMatch = eventId => {
    const event = getCurrentEvent()
    if (event.id !== eventId) {
        return false
    }

    return removeCurrentEvent()
}

export const createDB = (nameDb, storeName) => {
    const db = new Dexie(nameDb)
    db.version(1).stores({ [storeName]: '++_id' })

    return db
}

export const saveRecord = (table, data) => {
    return table.add(data)
}

export const removeRecord = (table, id) => {
    return table.delete(id)
}
