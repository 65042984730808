import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom'
import cx from 'classnames'

import LeadsPage from '../components/Pages/LeadsPage';
import VideoPage from '../components/Pages/VideoPage';
import SpinnerPage from '../components/Pages/SpinnerPage';
import FooterPageLinks from '../components/Pages/FooterPageLinks';
import Message from '../components/Message';

class FrontEndContainer extends PureComponent {
    constructor(props, context) {
        super(props, context)

        this.state = {
            page: 0,
            enableSubmit: false,
            spinner: {
                animate: true
            }
        }

        this._main = null
        this._footer = null

        this.enableSubmit = this.enableSubmit.bind(this)
        this.prevPage = this.prevPage.bind(this)
        this.nextPage = this.nextPage.bind(this)
        this.goToHome = this.goToHome.bind(this)
    }

    componentDidMount = () => {
        this._main = findDOMNode(this.refs.main)
        this._footer = findDOMNode(this.refs.footer)
    }

    componentDidUpdate = (_, prevState) => {
        if (this._footer) {
            this._footer.classList[this.state.page === -1 ? 'add' : 'remove']('app__footer--push')
        }

        if (prevState.page !== this.state.page) {
            this._main.scrollTop = 0;
        }
    }

    prevPage = () => this.setState(state => ({
        page: state.page - 1
    }))

    nextPage = () => this.setState(state => ({
        page: state.page + 1
    }))

    goToHome = () => {
        this.setState({
            page: 0,
            spinner: {
                animate: true
            }
        })
    }

    _finishSpinner = type => {
        this.setState({
            spinner: {
                ...this.state.spinner,
                type,
                finish: true
            }
        })
    }

    _sendFinishSpinner = (type, startTime) => {
        const diff = 4000 - Math.abs(new Date() - startTime)

        window.setTimeout(
            () => this._finishSpinner(type),
            Math.min(0, Math.abs(diff))
        )
    }

    enableSubmit = (enable: false) => {
        this.setState({
            enableSubmit: enable
        })
    }

    handleSubmit = () => {
        this.setState({
            page: -1
        })

        const startTime = new Date()
        const formData = {
            ...this.refs.form.getData(),
            created_at: startTime
        }

        this.props.saveRecord(formData)
            .then(({json, response}) => this._sendFinishSpinner('success', startTime))
            .catch(() => this._sendFinishSpinner('error', startTime))
    }

    _renderContent = valid => {
        if (!valid) {
            return null;
        }

        if (this.state.page === -1) {
            return <SpinnerPage handleHome={this.goToHome} spinnerProps={this.state.spinner} />
        } else if (this.state.page === 0) {
            return <VideoPage entity={this.props.entity} />
        }

        return <LeadsPage enableSubmit={this.enableSubmit} ref="form" />
    }

    _renderFooter = valid => {
        if (!valid) {
            return null;
        }

        return (
            <footer className="app__footer" ref="footer">
                <FooterPageLinks
                    page={this.state.page}
                    handleNextPage={this.nextPage}
                    handlePrevPage={this.prevPage}
                    enableSubmit={this.state.enableSubmit}
                    handleSubmit={this.handleSubmit}
                    nextText="¡Apúntate!"
                    acceptText="Enviar"
                />
            </footer>
        )
    }

    render = () => {
        const validEvent = this.props.entity.hasOwnProperty('title')

        return (
            <div className="app__wrapper">
                <main className={cx("app__main", {'is-clipped': !validEvent})} ref="main">
                    <h1 className="app__title">
                        {validEvent ? this.props.entity.title : null}
                    </h1>
                    {this._renderContent(validEvent)}
                </main>
                {this._renderFooter(validEvent)}
                {!validEvent
                    ? <div className="modal-message is-overlay"><Message title="No hay ninguna evento seleccionado" text="Antes de empezar, debes seleccionar un evento en el apartado de administración, una vez seleccionado desparecerá el mensaje." /></div>
                    : null
                }
            </div>
        )
    }
}

FrontEndContainer.propTypes = {
    entity: PropTypes.object,
    saveRecord: PropTypes.func.isRequired
}

FrontEndContainer.defaultProps = {
    entity: {}
}

export default FrontEndContainer;
