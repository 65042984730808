import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import SpinLoading from '../SpinLoading'

class SpinnerPage extends PureComponent {
    UNSAFE_componentWillReceiveProps = nextProps => {
        if (nextProps.spinnerProps.hasOwnProperty('finish') && nextProps.spinnerProps.finish) {
            window.setTimeout(this.props.handleHome, 4000)
        }
    }

    render = () => {
        return (
            <div className="loading">
                <SpinLoading {...this.props.spinnerProps} />
            </div>
        )
    }
}

SpinnerPage.propTypes = {
    handleHome: PropTypes.func.isRequired,
    spinnerProps: PropTypes.object
}
SpinnerPage.defaultProps = {
    spinnerProps: {}
}

export default SpinnerPage
