// import { normalize } from 'normalizr'
import fetch from 'isomorphic-fetch'
import { isObject } from 'lodash'

export const API_ROOT = process.env.NODE_ENV === 'development'
    ? 'http://lumen.test'
    : 'https://app.statsport.es'

const defaultHeaders = {
    'X-Requested-With': 'XMLHttpRequest',
    'token': 'mytoken'
}

// API FUNCTION
export const fetchRequest = (url = '', options = {}) => {
    options = Object.assign({
        method: 'GET'
    }, options)

    const { method } = options
    let { headers, body } = options

    headers = Object.assign({}, defaultHeaders, headers)
    if (isObject(body) && body.constructor.name === 'Object') {
        body = JSON.stringify(body)
        headers['Accept'] = 'application/json'
        headers['Content-Type'] = 'application/json'
    }

    const fullUrl = (url.indexOf(API_ROOT) === -1) ? API_ROOT + url : url

    return fetch(fullUrl, { headers, method, body, credentials: 'same-origin' })
        .then(response =>
            response.json().then(json => ({ json, response }))
        )
}
